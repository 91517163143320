"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SttIMC", {
  enumerable: true,
  get: function get() {
    return _imc["default"];
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "fieldNames", {
  enumerable: true,
  get: function get() {
    return _fieldNames["default"];
  }
});
Object.defineProperty(exports, "validationSchema", {
  enumerable: true,
  get: function get() {
    return _validationSchema["default"];
  }
});
var _lib = _interopRequireDefault(require("./lib"));
var _validationSchema = _interopRequireDefault(require("./lib/form/validationSchema"));
var _fieldNames = _interopRequireDefault(require("./lib/form/fieldNames"));
var _imc = _interopRequireDefault(require("./lib/imc"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
/** Componente */
var _default = _lib["default"];
exports["default"] = _default;