import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '../../redux/actions';
import { setOpen as setOpenAction } from '../../redux/actions/alerta';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Menu from '../../componentes/menu';
import Solicitacao from '../../paginas/solicitacao';
import Exames from '../../paginas/exame';
import PesquisaSolicitacoes from '../../paginas/envio-imagens';
import Administrativo from '../adminsitrativo';
import { SttCircularProgress, SttAlerta } from '@stt-componentes/core';
import ProtectedRoute from './protectedRoute';
import Erro401 from '../erro/401';
import Pendencias from '../pendencias';
import {
    PERFIL,
    PERMISSOES
} from '../../common/Constants';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import Laudo from '../laudo';
import Manutencao from '../erro/manutencao';
import LaudosGraves from '../laudos-graves';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = ({ isAuthenticated, setAuthenticated, setUser, setOpen, parametrosAlerta, user }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [moduloManutencao, setModuloManutencao] = useState(true);

    useEffect(() => {
        let idsPerfis = [];

        if (user?.perfisRBAC) {
            idsPerfis = user.perfisRBAC.map(objeto => objeto.identificador);
        }
        if (
            !global.gConfig.manutencao ||
            global.gConfig.perfis_liberacao_modulo.some(perfil => idsPerfis.includes(perfil)) ||
            global.gConfig.logins_liberacao_modulo.some(login => login === user.login)
        ) {
            setModuloManutencao(false);
        }
    }, [user]);

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <>
                        {
                            moduloManutencao ?
                                <Manutencao /> :
                                <div className={classes.wrapper}>
                                    <Menu />
                                    <Routes>
                                        <Route path="/" element={<ProtectedRoute component={Pendencias} permissao={PERMISSOES.ELETROCARDIOGRAFIA} />} />
                                        <Route path="/nao-autorizado" exact element={<Erro401 />} />
                                        <Route path='/solicitacao' exact element={<ProtectedRoute component={Solicitacao} permissao={PERMISSOES.SOLICITACAO} />} />
                                        <Route path='/imagens' exact element={<ProtectedRoute component={PesquisaSolicitacoes} permissao={PERMISSOES.VISUALIZAR_SOLICITACAO} />} />
                                        <Route path='/exames/*' exact element={<ProtectedRoute component={Exames} permissao={PERMISSOES.VISUALIZAR_EXAME} />} />
                                        <Route path="/laudos-graves" exact element={<ProtectedRoute component={LaudosGraves} perfil={[ PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL ]} />} />
                                        <Route path="/laudo" exact element={<ProtectedRoute component={Laudo} permissao={PERMISSOES.LAUDAR_EXAME_FABRICA} />} />
                                        <Route path="/administrativo/*" element={<ProtectedRoute component={Administrativo} permissao={PERMISSOES.VISUALIZAR_EXAME}/>}/>
                                    </Routes>

                                    <SttAlerta
                                        open={parametrosAlerta.open}
                                        title={parametrosAlerta.titulo}
                                        message={parametrosAlerta.mensagem}
                                        type={parametrosAlerta.tipo}
                                        onClose={() => {
                                            if (parametrosAlerta.onClose) {
                                                parametrosAlerta.onClose();
                                                return;
                                            }
                                            setOpen(false);
                                        }}
                                        options={parametrosAlerta.opcoes || []}
                                    />
                                </div>
                        }
                    </>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            setAuthenticated(loaded);

                            const user = Cache.getUserInfo();
                            setUser(user);
                        }} />
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        isAuthenticated: state.index.isAuthenticated,
        parametrosAlerta: state.alertaReducer.parametros,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);
