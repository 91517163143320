import TYPES from '../types/envioImagens'

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_PARAMETROS_PESQUISA_SOLICITACAO,
        payload: { parametros }
    };
}

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA_SOLICITACAO
    };
}