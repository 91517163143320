import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import Utils from '../../utils';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { user, ...other } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [gerandoPdf, setGerandoPdf] = useState(false);

    const imprimir = (idExame) => {
        setGerandoPdf(true);
        Utils.imprimirFn({ id: idExame }, () => setGerandoPdf(false));
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirProtocoloFn(exame, () => setGerandoPdf(false));
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirTcleFn({ id: exame.id_paciente }, () => setGerandoPdf(false), exame.origem_paciente);
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Aba);