import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../redux/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttAlerta, SttButton, SttHidden, SttLoading, SttTranslateHook } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from './imagens';
import Invalidar from '../exame/invalidar';
import { useMoment } from '../../hooks';
import ComparacaoExames from './comparacao';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media (min-width: 0px) and (max-width: 576px)': {
            justifyContent: 'flex-start'
        }
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ContainerLaudo = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { idExame, idRede, setLaudar, callbackProximoExame, callbackExameCarregado, notificar } = props;

    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    const location = useLocation();
    const moment = useMoment();
    const classes = useStyles();
    const [exame, setExame] = useState(null);
    const [examesComparacao, setExamesComparacao] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
    const [atencao, setAtencao] = useState(false);
    const [carregando, setCarregando] = useState(true);

    useEffect(() => {
        if (exame && examesComparacao) {
            setCarregando(false);
        }
    }, [exame, examesComparacao]);

    // Invalidação do exame
    const [invalidar, setInvalidar] = useState(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
        }
    };

    const [opcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const tratarDadosExame = (dados) => {
        // Tratar dados dos motivos de solicitação
        if (dados.indicacaoEletro.motivosSolicitacao?.length) {
            let motivosSolicitacao = '';
            dados.indicacaoEletro.motivosSolicitacao.forEach((motivo, indice) => {
                if (motivo.tipo) {
                    motivosSolicitacao += `${motivo.descricao} (${motivo.tipo_formatado})`;
                } else {
                    motivosSolicitacao += motivo.descricao;
                }

                if (dados.motivosSolicitacao && indice < dados.motivosSolicitacao.length - 1) {
                    motivosSolicitacao += ', ';
                }
            });

            dados.indicacaoEletro.motivosSolicitacaoFormatado = motivosSolicitacao;
        };

        // Tratar dados da indicação clínica
        if (dados.indicacaoEletro.dorIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica = dados.indicacaoEletro.dorIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoIsquemica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica = dados.indicacaoEletro.dorNaoIsquemica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorNaoCardiaca) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca = dados.indicacaoEletro.dorNaoCardiaca.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.dorToracica) {
            dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica = dados.indicacaoEletro.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.fatorRisco) {
            dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco = dados.indicacaoEletro.fatorRisco.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.localizacaoDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor = dados.indicacaoEletro.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.medicamento) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento = dados.indicacaoEletro.medicamento.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.motivoExame) {
            dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame = dados.indicacaoEletro.motivoExame.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.sintomaDor) {
            dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor = dados.indicacaoEletro.sintomaDor.map(e => e.descricao).join(', ');
        }

        if (dados.indicacaoEletro.valvulopatia) {
            dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia = dados.indicacaoEletro.valvulopatia.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.dorToracica) {
            dados.exame.descricaoDorToracicaRealizacaoExame = dados.exame.dorToracica.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.localizacaoDor) {
            dados.exame.descricaoLocalizacaoDorRealizacaoExame = dados.exame.localizacaoDor.map(e => e.descricao).join(', ');
        }

        if (dados.exame?.tipoDor) {
            dados.exame.descricaoTipoDorRealizacaoExame = dados.exame.tipoDor.map(e => {
                return e.complemento ? e.complemento : e.descricao
            }).join(', ');
        }

        return dados;
    };

    const verificarExamesComparacao = (data) => {
        const params = {
            idInstituicao: data.exame.id_instituicao,
            idExame: data.exame.id_exame,
            dataNascimentoPaciente: data.exame.data_nascimento_paciente,
            idSexoPaciente: data.exame.id_sexo_paciente
        }

        axios
            .get(`${ELETRO_API_BASE_URL}/comparar-exames`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response;
                    setExamesComparacao(data);
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }
                setMensagemErro(msg);
                setErro(true);
            });
    };

    useEffect(() => {
        if (idExame) {
            axios
                .get(`${ELETRO_API_BASE_URL}/laudo/${idExame}/fabrica/${contextoFabricalaudo}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        let dataMoment = moment(data.exame.data_nascimento_paciente);
                        if (dataMoment.isValid()) {
                            var now = moment();
                            var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                            var idade = moment.duration(now.diff(dataMomentFormatada));
                            var anos = idade.years();
                            data.exame.data_nascimento_paciente_formatada += ` (${anos} - ${anos+1} anos)`
                        }

                        // Caso seja fábrica de laudos, deve-se verificar exames repetidos para comparação
                        // Caso não seja, seta o array para vazio para que o sistema abra a tela de laudos
                        if (contextoFabricalaudo) {
                            verificarExamesComparacao(data);
                        } else {
                            setExamesComparacao([]);
                        }
                        setExame(tratarDadosExame(data));
                        if (contextoFabricalaudo) {
                            callbackExameCarregado();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.mensagemErroGeral;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setMensagemErro(msg);
                    setErro(true);
                });
        }

        return () => {
            setLaudar(false);
            cancelarEmissao();
        }
    }, []);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        setLaudar(false);

        // Cancela emissão do laudo
        cancelarEmissao();
    };

    const cancelarEmissao = () => {
        axios
            .post(`${ELETRO_API_BASE_URL}/cancelar-laudo`, { idExame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    };

    const ignorarExame = () => {
        setExame(null);
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios
            .post(`${ELETRO_API_BASE_URL}/ignorar-exame`, { idExame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                setMensagemErro(strings.erroIgnorarExame);
                setErro(true);
            });
    }

    const ignorarExamesComparacao = () => {
        setExamesComparacao([]);
    };

    const callbackInvalidarExamesComparacao = () => {
        setExamesComparacao(null);
        callbackProximoExame();
    };

    const DivBotoes = () => {
        return (
            <>
                {
                    exame && examesComparacao && examesComparacao.length === 0 &&
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => setInvalidar(true)}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={voltar}
                                >
                                    {strings.voltar}
                                </SttButton>
                            }

                            {
                                contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={ignorarExame}
                                >
                                    {strings.proximoExame}
                                </SttButton>
                            }

                        </SttGrid>
                    </SttGrid>
                }
            </>
        );
    }

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={carregando}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame && examesComparacao && examesComparacao.length > 0 &&
                <>
                    <ComparacaoExames
                        exameAtual={exame}
                        examesComparacao={examesComparacao}
                        callbackInvalidar={callbackInvalidarExamesComparacao}
                        callbackIgnorarExamesComparacao={ignorarExamesComparacao}
                        ignorarExame={ignorarExame}
                    />
                </>
            }
            {
                exame && examesComparacao && examesComparacao.length === 0 &&
                <>
                    <SttGrid container spacing={3}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                <Imagens idExame={idExame} anexos={exame.anexos} />
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} DivBotoes={DivBotoes} />
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={8}>
                                <StickyBox>
                                    <Imagens idExame={idExame} anexos={exame.anexos} />
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={4}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} DivBotoes={DivBotoes} />
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar &&
                <Invalidar
                    invalidar={invalidar}
                    setInvalidar={setInvalidar}
                    invalidarSemConfirmacao={true}
                    idExame={idExame}
                    idRede={idRede}
                    callback={callbackProximoExame}
                />
            }
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
            <SttAlerta
                open={atencao}
                title={strings.atencao}
                message={strings.laudoEmEmissao}
                type="alert"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );
};

ContainerLaudo.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerLaudo);