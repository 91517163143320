import axios from 'axios';
import { getHeaders } from '../request';
import { NotificationManager } from 'react-notifications';

const Utils = {

    imprimirFn: (exame, callback) => {
        const idExameCodificado = Buffer.from(exame.id.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: { 
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                imagens: true
            }
        };
        axios.get(`${global.gConfig.url_base_exames}/exame/${idExameCodificado}/imprimir`, opcoes)
            .then((response) => {
                if (response.data) {
                    window.open(response.data);
                }
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    imprimirProtocoloFn: (exame, callback) => {
        const idExameCodificado = Buffer.from(exame.id.toString()).toString('base64');
        const params = {
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios.get(`${global.gConfig.url_base_exames}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    imprimirTcleFn: (paciente, callback, tipoPaciente = 'D') => {
        const idCodificado = Buffer.from(paciente.id.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: { 
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                'tipo-paciente': tipoPaciente
            }
        };
        let url = `${global.gConfig.url_base_exames}/exame/tcle/${idCodificado}`;
        axios
            .get(url, opcoes)
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    }
};

export default Utils;