"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialValues = void 0;
var initialValues = {
  id: '',
  cpf: '',
  cns: '',
  nome: '',
  nomeMae: '',
  genero: null,
  dataNascimento: null,
  municipio: null,
  uf: null,
  pais: null,
  peso: '',
  altura: '',
  profissao: '',
  grupoSanguineo: null,
  fatorRh: null,
  raca: null,
  cep: '',
  logradouro: '',
  bairro: '',
  tipoContato1: 1,
  contato1: '',
  tipoContato2: 2,
  contato2: '',
  idade: '',
  email: '',
  tipoContatoEmail: 4
};
exports.initialValues = initialValues;